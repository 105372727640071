import React, { useState, useEffect } from 'react';
import Link from 'next/link';

interface HeaderProps {
  onOpenWaitlist?: () => void;
}

const Header: React.FC<HeaderProps> = ({ onOpenWaitlist }) => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header 
      className={`fixed w-full z-10 transition-all duration-300 ${
        scrolled ? 'py-2' : 'py-4'
      }`}
    >
      <div className="container mx-auto px-4">
        <div className={`${scrolled ? 'bg-blue-700/90 backdrop-blur-sm' : 'bg-white/10 backdrop-blur-sm'} py-3 px-5 flex justify-between items-center shadow-sm rounded-full`}>
          <Link href="/" className="text-2xl font-bold text-white">
            Zettlor
          </Link>

          <button
            onClick={onOpenWaitlist}
            className="px-4 py-2 text-white rounded-md hover:text-blue-100 transition-colors font-medium cursor-pointer"
          >
            Join waitlist
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header; 