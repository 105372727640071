import React, { useEffect, useState } from 'react';
import { TrendingUp } from 'lucide-react';

const generateRandomData = (baseData: number[], amplitude: number) => {
  return baseData.map((val) => val + (Math.random() * amplitude - amplitude / 2));
};

const AnimatedChart: React.FC = () => {
  const [data1, setData1] = useState([25, 50, 75, 60, 80, 65, 90, 70]);
  const [data2, setData2] = useState([75, 60, 40, 65, 45, 70, 55, 40]);
  
  useEffect(() => {
    // Update data with subtle changes to create animation effect
    const interval = setInterval(() => {
      setData1(prevData => generateRandomData(prevData, 3));
      setData2(prevData => generateRandomData(prevData, 3));
    }, 2000);
    
    return () => clearInterval(interval);
  }, []);
  
  // Create SVG path from data points with rounded corners
  const createSmoothPath = (data: number[], height: number, width: number): string => {
    const segments = data.length - 1;
    const segmentWidth = width / segments;
    
    if (data.length < 2) return '';
    
    // Start at the bottom left
    let d = `M 0,${height} `;
    
    // Move to the first data point
    const firstY = height - (data[0] / 100) * height;
    d += `L 0,${firstY} `;
    
    // Create a smooth line through all points
    for (let i = 0; i < data.length - 1; i++) {
      const x1 = i * segmentWidth;
      const y1 = height - (data[i] / 100) * height;
      const x2 = (i + 1) * segmentWidth;
      const y2 = height - (data[i + 1] / 100) * height;
      
      // Use curve commands to create smooth lines
      // Control points are set to create natural curves
      const ctrlX1 = x1 + segmentWidth / 3;
      const ctrlY1 = y1;
      const ctrlX2 = x2 - segmentWidth / 3;
      const ctrlY2 = y2;
      
      d += `C ${ctrlX1},${ctrlY1} ${ctrlX2},${ctrlY2} ${x2},${y2} `;
    }
    
    // Complete the path by going back to the bottom
    const lastX = (data.length - 1) * segmentWidth;
    d += `L ${lastX},${height} L 0,${height}`;
    
    return d;
  };
  
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];
  
  return (
    <div className="relative w-full h-full bg-[#f3ebd8] rounded-lg overflow-hidden border border-[#e2d8bd] shadow-lg">
      <div className="p-6">
        <div className="mb-3">
          <h3 className="text-lg font-semibold text-[#5c5142]">Monthly Visitors</h3>
          <p className="text-sm text-[#877e6a]">Showing total visitors for the last 6 months</p>
        </div>
        
        <div className="h-[250px] relative">
          <svg width="100%" height="100%" viewBox="0 0 400 200" preserveAspectRatio="none">
            {/* Grid lines */}
            <line x1="0" y1="50" x2="400" y2="50" stroke="#e2d8bd" strokeWidth="1" />
            <line x1="0" y1="100" x2="400" y2="100" stroke="#e2d8bd" strokeWidth="1" />
            <line x1="0" y1="150" x2="400" y2="150" stroke="#e2d8bd" strokeWidth="1" />
            
            {/* Data line 2 - Lower line */}
            <path
              d={createSmoothPath(data2, 180, 400)}
              fill="url(#gradient2)"
              stroke="#9c8a6b"
              strokeWidth="1.5"
              opacity="0.9"
              className="transition-all duration-1500 ease-in-out"
            />
            
            {/* Data line 1 - Upper line */}
            <path
              d={createSmoothPath(data1, 180, 400)}
              fill="url(#gradient1)"
              stroke="#7c6e56"
              strokeWidth="1.5"
              opacity="0.9"
              className="transition-all duration-1500 ease-in-out"
            />
            
            {/* X axis labels */}
            {months.map((month, i) => (
              <text 
                key={month} 
                x={i * 80} 
                y="195" 
                fill="#877e6a" 
                fontSize="12"
                textAnchor="middle"
              >
                {month}
              </text>
            ))}
            
            {/* Gradient definitions */}
            <defs>
              <linearGradient id="gradient1" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#7c6e56" stopOpacity="0.6" />
                <stop offset="100%" stopColor="#7c6e56" stopOpacity="0.1" />
              </linearGradient>
              <linearGradient id="gradient2" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#9c8a6b" stopOpacity="0.6" />
                <stop offset="100%" stopColor="#9c8a6b" stopOpacity="0.1" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        
        <div className="mt-4 flex items-center gap-2 text-[#5c5142] text-sm">
          <span className="flex items-center gap-1">
            <TrendingUp className="h-4 w-4" />
            Trending up by 5.2% this month
          </span>
        </div>
        <div className="text-[#877e6a] text-xs">
          January - June 2024
        </div>
      </div>
    </div>
  );
};

export default AnimatedChart; 