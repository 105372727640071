import React from 'react';
import Head from 'next/head';

interface LandingLayoutProps {
  children: React.ReactNode;
  title?: string;
  description?: string;
}

const LandingLayout: React.FC<LandingLayoutProps> = ({ 
  children, 
  title = 'Zettlor - Perfectly Tune your Sauna Business', 
  description = 'Our generative pricing optimizes occupancy throughout the day. Partners see 30-200% more annual revenue.'
}) => {
  return (
    <div className="min-h-screen flex flex-col">
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
        <script src="https://web3forms.com/client/script.js" async defer></script>
      </Head>
      <main className="flex-grow">{children}</main>
    </div>
  );
};

export default LandingLayout; 