import React, { useState } from 'react';
import {
  LandingLayout,
  Header,
  HeroSection,
  StatsSection,
  WaitlistSection,
  Footer
} from '../components/marketing-pages/booker-homepage';

const HomePage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <LandingLayout>
      <Header onOpenWaitlist={openModal} />
      <HeroSection 
        isModalOpen={isModalOpen}
        onOpenModal={openModal}
        onCloseModal={closeModal}
      />
      <StatsSection />
      <WaitlistSection />
      <Footer />
    </LandingLayout>
  );
};

export default HomePage;
