import React, { useEffect, useState } from 'react';
import WaitlistModal from './WaitlistModal';
import Image from 'next/image';
import AnimatedChart from './AnimatedChart';

interface HeroSectionProps {
  isModalOpen: boolean;
  onOpenModal: () => void;
  onCloseModal: () => void;
}

const HeroSection: React.FC<HeroSectionProps> = ({ 
  isModalOpen, 
  onOpenModal,
  onCloseModal
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Add a small delay to ensure the animation runs after page load
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <section className="bg-gradient-to-br from-blue-700 via-blue-600 to-blue-500 min-h-screen flex items-center pt-20 relative overflow-hidden">
        {/* Background pattern - subtle curved lines */}
        <div className="absolute inset-0 opacity-10">
          <svg
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <pattern
                id="smallGrid"
                width="10"
                height="10"
                patternUnits="userSpaceOnUse"
              >
                <path
                  d="M 10 0 L 0 10"
                  fill="none"
                  stroke="white"
                  strokeWidth="0.5"
                />
              </pattern>
            </defs>
            <rect width="100%" height="100%" fill="url(#smallGrid)" />
          </svg>
        </div>

        <div className="container mx-auto px-4 md:px-8 py-16 md:py-24 grid grid-cols-1 lg:grid-cols-2 gap-12 items-center relative z-10">
          <div className="text-left lg:pr-12">
            <h1 
              className={`text-4xl md:text-5xl lg:text-6xl font-bold text-green-300 mb-6 leading-tight transition-all duration-700 ${
                isLoaded ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-10'
              }`}
            >
              Perfectly Tune your Sauna Business
            </h1>
            
            <p 
              className={`text-xl md:text-2xl text-white mb-8 transition-all duration-700 delay-300 ${
                isLoaded ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
              }`}
            >
              Prevent overcrowding and grow your revenue at the same time
            </p>
            
            {/* <p 
              className={`text-lg text-white mb-12 transition-all duration-700 delay-500 ${
                isLoaded ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
              }`}
            >
              Our generative pricing optimizes occupancy throughout the day. Partners see 30-200% more annual revenue.
            </p> */}
            
            <div 
              className={`flex flex-col sm:flex-row gap-4 transition-all duration-500 delay-700 ${
                isLoaded ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
              }`}
            >
              <button 
                onClick={onOpenModal}
                className="px-8 py-3 bg-white text-blue-700 rounded-md hover:bg-blue-50 transition-colors font-medium text-lg shadow-lg"
              >
                Join the waitlist
              </button>
              <a 
                href="tel:+13857585558"
                className="px-8 py-3 border border-white text-white rounded-md hover:bg-white/10 transition-colors font-medium text-lg flex items-center justify-center"
              >
                +1 (385) 758-5558
              </a>
            </div>
          </div>
          
          <div className={`hidden lg:block transition-all duration-1000 ${
            isLoaded ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-20'
          }`}>
            {/* Animated Chart Component */}
            <div className="w-full shadow-xl rounded-lg overflow-hidden">
              <AnimatedChart />
            </div>
          </div>
        </div>
        
        {/* Company logos */}
        <div className="absolute bottom-0 left-0 right-0 bg-white/5 backdrop-blur-sm py-6 border-t border-white/10">
          <div className="container mx-auto">
            <div className="flex flex-wrap justify-center items-center gap-12">
              <div className="text-white/70 font-medium">Protect your experience</div>
            </div>
          </div>
        </div>
      </section>

      <WaitlistModal isOpen={isModalOpen} onClose={onCloseModal} />
    </>
  );
};

export default HeroSection;